import { IProduct } from 'app/models/product';
import { BehaviorSubject } from 'rxjs';
import {  HostListener, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }

  add(product: IProduct) : Promise<any> {
    return this.httpService.post(`admin/products`, product);
  }

  getListPage(page: number = 1, search:any = "") : Promise<any> {

    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));

    return this.httpService.get(`admin/products/list`, params)
  }
  getProductWithStockList(page: number = 1, search:any = "") : Promise<any> {

    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));

    return this.httpService.get(`admin/products/getProductWithStockList`, params)
  }
  

  getFullListPage(page: number = 1, status: string = "", search: any = "", ) : Promise<any> {
    return this.httpService.get(`admin/products/fullist`, {page: page, status: status, search: search})
  }

  getProductBySku(sku: any ,store:any) : Promise<any> {
    return this.httpService.get(`admin/products/${sku}/detail/${store}`);
  }

  addProductCategory(sku: string, id_category: string) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/category`, {id_category: id_category});
  }

  addProductImage(sku: string, urls: any[]) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/images`, {urls: urls});
  }

  trashProductCategory(sku: string, id_category: string) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/category/${id_category}`);
  }

  trashProduchBySku(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/trash`);
  }

  restoreProductTrashBySku(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/restore`);
  }

  updateProductStatusBySku(sku: string ) : Promise<any> {
    return this.httpService.put(`admin/products/${sku}/status`);
  }

}
