import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }

  getListPage(page: number = 1, status: any  = "all", type: any  = "all", search: any  = "") : Promise<any> {
    return this.httpService.get(`admin/orders/list`, {page: page, status: status, type: type, search: search})
  }

  getOrderByUoc(uoc: string) : Promise<any> {
    return this.httpService.get(`admin/orders/${uoc}/detail`);
  }

  trashOrderByUoc(uoc: string) : Promise<any> {
    return this.httpService.put(`admin/orders/${uoc}/trash`);
  }

  restoreOrderTrashByUoc(uoc: string) : Promise<any> {
    return this.httpService.put(`admin/orders/${uoc}/restore`);
  }

  nofityOrder(id: string, nota: string) : Promise<any> {
    return this.httpService.put2(`admin/orders/${id}/notify`, {nota: nota});
  }
  nofityOrderPlus(id: string, nota: string,tracking:string) : Promise<any> {
    return this.httpService.put(`admin/orders/${id}/notify`, {nota: nota,tracking_id:tracking});
  }


  nofityWithdraw(id: string, nota: string) : Promise<any> {
    return this.httpService.put(`admin/orders/${id}/withdraw`, {nota: nota});
  }
}
