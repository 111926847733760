import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UrlShortServices {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }



  getShort(store_id:any,urlLong: any,body:any ="{}") : Promise<any> {
    return  this.httpService.post2(`admin/shortener/${store_id}/generate`,{url:String(urlLong),body:body})
  }

  getShortSimple(store_id:any,urlLong: any) : Promise<any> {
    return  this.httpService.post2(`admin/shortener/${store_id}/generate`,{url:String(urlLong)})
  }

}
